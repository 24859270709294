"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const consts_1 = require("./consts");
const template_1 = require("./template");
const styles_1 = require("./styles");
function script({ malomoKey, page }) {
    const selector = `[data-js-page=${page}]`;
    const target = document.querySelector(selector);
    const others = document.querySelectorAll(`[data-js-page]:not(${selector})`);
    for (let i = 0; i < others.length; i++) {
        // @ts-expect-error FIXME: Property 'style' does not exist on type 'Element'
        others[i].style.display = 'none';
    }
    // @ts-expect-error FIXME: Property 'style' does not exist on type 'Element'
    target.style.display = 'block';
    if (!malomoKey) {
        return;
    }
    const enableOrderLookup = true;
    const orderNumberPrefix = '';
    const orderNumberSuffix = '';
    const trackingContainerEl = document.querySelector('.tracking-container.wf-section');
    if (!trackingContainerEl) {
        return;
    }
    trackingContainerEl.innerHTML =
        '<div class="tracking-container-bg"></div><div class="malomo-unavailable"><div class="malomo-unavailable-header">Unavailable</div><div class="malomo-unavailable-body"><div class="malomo-unavailable-message">Tracking information is unavailable at this time. You can try entering your tracking number into your carrier&#x27;s website for more information.</div><div class="malomo-unavailable-links"><a href="https://www.ups.com/tracking.html" target="_blank" class="malomo-unavailable-link">Check with UPS</a><br><a href="https://tools.usps.com/go/TrackConfirmAction_input" target="_blank" class="malomo-unavailable-link">Check with USPS</a><br><a href="https://www.fedex.com/en-us/tracking.html" target="_blank" class="malomo-unavailable-link">Check with FedEx</a><br><a href="https://ecommerceportal.dhl.com/track/" target="_blank" class="malomo-unavailable-link">Check with DHL eCommerce</a></div></div></div>';
    // @ts-expect-error Malomo is undefined (global import)
    const malomo = Malomo(malomoKey); // eslint-disable-line new-cap, no-undef
    const elements = malomo.elements();
    const shipmentTracker = malomo.fetchOrder().then((order) => {
        return elements
            .create('shipmentTracker', {
            order: order,
            carrierInfo: { linkToCarrier: true }
        })
            .mount('.tracking-container');
    });
    if (enableOrderLookup) {
        shipmentTracker.catch(() => {
            elements
                .create('orderLookup', {
                orderNumberPrefix: new RegExp('^#?' + orderNumberPrefix),
                orderNumberSuffix: new RegExp(orderNumberSuffix + '$'),
                callback: (resp) => {
                    const url = new URL(document.URL);
                    url.searchParams.append('_m_id', resp.body.id);
                    // @ts-expect-error FIXME: Type 'URL' is not assignable to type 'string'
                    window.location.href = url;
                }
            })
                .mount('.tracking-container');
        });
    }
    else {
        shipmentTracker.catch(() => {
            const el = document.querySelector('.malomo-unavailable');
            // @ts-expect-error FIXME: Property 'style' does not exist on type 'Element'
            el.style.display = 'flex';
        });
    }
}
function shipmentTracking(editor, opts = {}) {
    const options = Object.assign({ extendTraits: (traits) => traits }, opts);
    const domc = editor.DomComponents;
    const defaultType = domc.getType('default');
    const defaultModel = defaultType.model;
    editor.BlockManager.add(consts_1.shipmentTrackingRef, {
        category: 'Malomo',
        label: 'Shipment Tracking',
        // @ts-expect-error FIXME: Types seeem incorrect
        attributes: { class: 'fa fa-truck' },
        content: `<div ${consts_1.shipmentTrackingAttributes}></div>`
    });
    domc.addType(consts_1.shipmentTrackingType, {
        model: defaultModel.extend({
            defaults: Object.assign(Object.assign({}, defaultModel.prototype.defaults), { script, page: 'page1', malomoKey: '', components: (0, template_1.trackingTemplate)(), styles: styles_1.trackingStyles, 
                // @ts-expect-error FIXME: Types seeem incorrect
                traits: options.extendTraits([
                    {
                        type: 'select',
                        name: 'page',
                        label: 'Page',
                        changeProp: 1,
                        options: [
                            { id: 'page1', name: 'Page 1' },
                            { id: 'page2', name: 'Page 2' },
                            { id: 'page3', name: 'Page 3' }
                        ]
                    },
                    {
                        type: 'text',
                        name: 'malomoKey',
                        label: 'Malomo API Key',
                        changeProp: 1,
                        placeholder: 'Insert key'
                    }
                ]), 'script-props': ['page', 'malomoKey'] })
        }, {
            // eslint-disable-next-line consistent-return
            isComponent: (el) => {
                if (el.getAttribute &&
                    el.getAttribute('data-gjs-type') === consts_1.shipmentTrackingType) {
                    return { type: consts_1.shipmentTrackingType };
                }
            }
        }),
        view: defaultType.view
    });
}
exports.default = shipmentTracking;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UNINSTALL_APP = exports.GET_APP_LISTINGS = void 0;
const client_1 = require("@apollo/client");
exports.GET_APP_LISTINGS = (0, client_1.gql) `
  query appListings($accountId: ID!) {
    appListings(accountId: $accountId) {
      id
      insertedAt
      installUrl
      installed
      name
      category
      updatedAt
      clientId
    }
  }
`;
exports.UNINSTALL_APP = (0, client_1.gql) `
  mutation Uninstall($input: UninstallInput!) {
    uninstall(input: $input) {
      ok
    }
  }
`;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthContext = void 0;
const react_1 = require("react");
// eslint-disable-next-line @typescript-eslint/no-empty-function
let handleLogout = () => { };
const AuthContext = (0, react_1.createContext)({
    logout: () => {
        handleLogout();
    },
    setLogoutFunction: function (fn) {
        handleLogout = fn;
    }
});
exports.AuthContext = AuthContext;

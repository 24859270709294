"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_ORDERS = void 0;
const client_1 = require("@apollo/client");
exports.GET_ORDERS = (0, client_1.gql) `
  query orders(
    $accountId: ID!
    $q: String
    $after: String
    $before: String
    $first: Int
    $last: Int
    $issues: [String!]
    $shipmentStatuses: [String!]
  ) {
    orders(
      accountId: $accountId
      q: $q
      after: $after
      before: $before
      first: $first
      last: $last
      issues: $issues
      shipmentStatuses: $shipmentStatuses
    ) {
      edges {
        node {
          insertedAt
          number
          customer {
            email
            firstName
            id
            lastName
          }
          shipments {
            id
            arrival
            trackingCode
            carrierUrl
            carrier
            carrierName
            status
            estimatedDeliveryDate
            originalEstimatedDeliveryDate
          }
          issues {
            edges {
              node {
                id
                insertedAt
                rule {
                  name
                }
                shipment {
                  id
                }
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;

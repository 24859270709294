"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shippingCopy = void 0;
exports.shippingCopy = {
    loading: 'Loading Shipments',
    fetchError: 'The server could not be reached, please try again later',
    noShipmentsHeader: 'No reports yet.',
    noShipmentsParagraph: 'Once Malomo is connected to your store account, reports will be added automatically.',
    subHeader: 'REPORTS',
    mainHeader: 'Shipping Performance',
    section1: 'Arrival Time',
    section2: 'SHIPMENTS TRACKED',
    section3: 'Time Averages in Days',
    section4: 'PLACED TO DELIVERY',
    section5: 'PLACED TO FULFILLED',
    section6: 'FULFILLED TO DELIVERY',
    tableButton: 'Details',
    column1: 'Order',
    column2: 'Tracking Code',
    column3: 'Carrier',
    column4: 'Delivery Date',
    column5: 'Arrival',
    modaHeader1: 'Order #',
    modaHeader3: 'PLACED',
    modaHeader4: 'FULFILLED',
    modaHeader5: 'ESTIMATED DELIVERY',
    modaHeader6: 'DELIVERED'
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.appsCopy = void 0;
exports.appsCopy = {
    loading: 'Loading',
    fetchError: 'The server could not be reached, please try again later',
    noAppsHeader: 'No apps yet.',
    noAppsParagraph: 'Once Malomo is connected to your store account, apps will be added automatically.',
    mainHeader: 'Apps',
    appConnected: 'Connected',
    uninstall: 'Uninstall',
    reinstall: 'Reinstall',
    install: 'Install'
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HIDE_CLASS = exports.BASE_CLASS = exports.FONT_WEIGHT_OPTIONS = exports.SECTOR_OPTIONS = void 0;
exports.SECTOR_OPTIONS = {
    GENERAL: 'general',
    LAYOUT: 'layout',
    TYPOGRAPHY: 'typography',
    DECORATE: 'decorate',
    EXTRA: 'extra'
};
exports.FONT_WEIGHT_OPTIONS = [
    { value: '100', name: 'Thin' },
    { value: '200', name: 'Extra-Light' },
    { value: '300', name: 'Light' },
    { value: '400', name: 'Normal' },
    { value: '500', name: 'Medium' },
    { value: '600', name: 'Semi-Bold' },
    { value: '700', name: 'Bold' },
    { value: '800', name: 'Extra-Bold' },
    { value: '900', name: 'Ultra-Bold' }
];
exports.BASE_CLASS = 'gjs-sm-sector';
exports.HIDE_CLASS = 'gjs-sm-sector__hide';

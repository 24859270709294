"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.trackingStyles = void 0;
exports.trackingStyles = `
  .malomo-order-lookup-submit-button {
    height: 48px;
    margin-top: 32px;
    margin-bottom: 16px;
    border-radius: 3px;
    background-color: #000;
    color: #fff;
    font-size: 14px;
    line-height: 150%;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: none;
  }

  .malomo-order-lookup-form {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .malomo-order-lookup-number-input {
    width: 100%;
    height: 42px;
    margin-bottom: auto;
    border-style: solid;
    border-width: 1px;
    border-color: #ddd;
    background-color: #f9f9f9;
  }

  .malomo-order-lookup-number-input:focus {
    border-width: 2px;
    border-color: #8fd7f3;
  }

  .malomo-order-lookup {
    position: relative;
    z-index: 2;
    width: 600px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 24px;
    padding-right: 24px;
    padding-left: 24px;
    clear: none;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(149, 170, 201, 0.1);
    background-color: #fff;
    -o-object-fit: contain;
    object-fit: contain;
  }

  .malomo-order-lookup-error-message {
    margin-bottom: 24px;
    padding: 15px 10px;
    border-radius: 3px;
    background-color: #e63757;
    color: #fff;
    font-size: 14px;
  }

  .malomo-order-lookup-section-1-1 {
    margin-bottom: 10px;
  }

  .malomo-order-lookup-label {
    color: #000;
  }

  .malomo-order-lookup-customer-email-input {
    width: 100%;
    height: 42px;
    border-style: solid;
    border-width: 1px;
    border-color: #ddd;
    background-color: #f9f9f9;
  }

  .malomo-order-lookup-tracking-number-input {
    width: 100%;
    height: 42px;
    border-style: solid;
    border-width: 1px;
    border-color: #ddd;
    background-color: #f9f9f9;
  }

  .malomo-order-lookup-section-separator {
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 700;
    text-align: center;
  }

  .malomo-shipment-tracker-event-timestamp {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 2px;
  }

  .malomo-shipment-tracker-most-recent-event {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .malomo-shipment-tracker-header {
    color: black;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    max-width: 400px;
    margin-right: auto;
    margin-bottom: 20px;
    margin-left: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
  }

  .malomo-shipment-tracker-event {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 12px;
    padding-bottom: 12px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    color: #000;
    font-size: 14px;
    line-height: 130%;
  }

  .malomo-shipment-tracker {
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100vw;
    height: 500px;
    margin-top: 68px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    color: #fff;
  }

  .malomo-shipment-tracker-event-timestamp-date {
    margin-right: 6px;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    color: #12263f;
    font-weight: 700;
    text-transform: capitalize;
  }

  .malomo-shipment-tracker-event-timestamp-time {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
    color: rgba(18, 38, 63, 0.7);
    font-weight: 400;
    text-transform: uppercase;
  }

  .malomo-shipment-tracker-delivery-date {
    display: block;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 56px;
    line-height: 100%;
    font-weight: 700;
    text-align: center;
    text-transform: capitalize;
  }

  .malomo-shipment-tracker-event-location {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #000;
    text-transform: capitalize;
  }

  .malomo-shipment-tracker-event-message {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    color: #000;
  }

  .malomo-shipment-tracker-delivery-state {
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .malomo-shipment-tracker-latest-activity {
    margin-bottom: 4px;
    color: #000;
    line-height: 150%;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .malomo-shipment-tracker-countdown {
    margin-top: 0px;
    margin-bottom: 8px;
    color: #000;
    font-size: 24px;
    line-height: 125%;
    font-weight: 400;
  }

  .malomo-shipment-tracker-carrier-info-image {
    max-height: 24px;
    max-width: 64px;
    margin-right: 8px;
  }

  .malomo-shipment-tracker-carrier-info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }

  .malomo-shipment-tracker-carrier-info-tracking-code-link {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
    color: #3d4412;
    font-size: 14px;
    line-height: 100%;
  }

  .malomo-shipment-tracker-history-control {
    padding: 9px 32px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
    border-radius: 3px;
    background-color: #95aac9;
    color: #fff;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
  }

  .malomo-shipment-tracker-footer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 600px;
    padding: 12px 24px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(149, 170, 201, 0.1);
    background-color: #fff;
  }

  .malomo-shipment-tracker-body {
    position: static;
    z-index: 2;
    width: 600px;
    padding-top: 24px;
    padding-right: 24px;
    padding-left: 24px;
    border-style: solid;
    border-width: 1px 1px 0px;
    border-color: rgba(149, 170, 201, 0.1);
    background-color: #fff;
  }

  .malomo-shipment-tracker-status-message {
    display: block;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 56px;
    line-height: 100%;
    font-weight: 700;
    text-align: center;
    text-transform: capitalize;
  }

  .malomo-shipment-tracker-history {
    overflow: auto;
  }

  .malomo-shipment-tracker-event-location-city::before {
    background-image: url("https://uploads-ssl.webflow.com/5daa15f6975912da0636ca13/5db0987c69d1f7b304691495_Location.svg");
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    display: inline-block;
    height: 12px;
    padding-right: 4px;
    width: 12px;
  }

  .malomo-shipment-tracker-event-location-city+.malomo-shipment-tracker-event-location-state::before {
    content: ", ";
  }

  .malomo-shipment-tracker-events-backdrop {
    position: fixed;
    left: 0%;
    top: 0%;
    right: auto;
    bottom: auto;
    z-index: 999;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.65);
    color: #000;
  }

  .malomo-shipment-tracker-events-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 24px;
    padding-bottom: 12px;
    color: #000;
  }

  .malomo-shipment-tracker-events-header-label {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    color: #000;
    font-size: 20px;
  }

  .malomo-shipment-tracker-events-modal {
    width: 600px;
    padding-right: 24px;
    padding-left: 24px;
    border-radius: 3px;
    background-color: #fff;
  }

  .malomo-shipment-tracker-events-header-close-icon {
    width: 20px;
    height: 20px;
  }

  .malomo-shipment-tracker-events {
    overflow: scroll;
    height: 400px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    color: #000;
  }

  .malomo-shipment-tracker-event-location-state {
    text-transform: uppercase;
  }

  .malomo-unavailable {
    display: none;
    width: 600px;
    height: 500px;
    margin-top: 68px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .malomo-unavailable-link {
    color: #12263f;
  }

  .malomo-unavailable-message {
    padding-top: 24px;
    padding-bottom: 12px;
    background-color: #fff;
  }

  .malomo-unavailable-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #fff;
    font-size: 56px;
    line-height: 100%;
    font-weight: 700;
    text-align: center;
  }

  .malomo-unavailable-body {
    padding-right: 24px;
    padding-bottom: 12px;
    padding-left: 24px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(149, 170, 201, 0.1);
    background-color: #fff;
  }

  .tracking-container {
    font-family: Montserrat, sans-serif;
    color: #12263f;
    font-size: 12px;
    line-height: 125%;
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 500px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .tracking-container-bg {
    position: absolute;
    left: 0%;
    top: 0%;
    right: auto;
    bottom: auto;
    z-index: -1;
    width: 100%;
    height: 500px;
    background-image: url("http://localhost:5000/images/16by9-image.jpg");
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .w-input,
  .w-select {
    display: block;
    width: 100%;
    height: 38px;
    padding: 8px 12px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.428571429;
    color: #333333;
    vertical-align: middle;
    background-color: #ffffff;
    border: 1px solid #cccccc;
  }

  .w-input:-moz-placeholder,
  .w-select:-moz-placeholder {
    color: #999;
  }

  .w-input::-moz-placeholder,
  .w-select::-moz-placeholder {
    color: #999;
    opacity: 1;
  }

  .w-input:-ms-input-placeholder,
  .w-select:-ms-input-placeholder {
    color: #999;
  }

  .w-input::-webkit-input-placeholder,
  .w-select::-webkit-input-placeholder {
    color: #999;
  }

  .w-input:focus,
  .w-select:focus {
    border-color: #3898ec;
    outline: 0;
  }

  .w-input[disabled],
  .w-select[disabled],
  .w-input[readonly],
  .w-select[readonly],
  fieldset[disabled] .w-input,
  fieldset[disabled] .w-select {
    cursor: not-allowed;
    background-color: #eeeeee;
  }

  textarea.w-input,
  textarea.w-select {
    height: auto;
  }

  .w-button {
    display: inline-block;
    padding: 9px 15px;
    background-color: #3898ec;
    color: white;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;
  }

  input.w-button {
    -webkit-appearance: button;
  }

  .w-form-done {
    display: none;
    padding: 20px;
    text-align: center;
    background-color: #dddddd;
  }

  .w-form-fail {
    display: none;
    margin-top: 10px;
    padding: 10px;
    background-color: #ffdede;
  }

  @media screen and (max-width: 991px) {
    .tracking-container {
      position: relative;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .malomo-shipment-tracker-body {
      top: -240px;
    }

    .malomo-container-new {
      padding-right: 0px;
    }

    .malomo-shipment-tracker-events-backdrop {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }

    .malomo-order-lookup {
      position: static;
      top: -240px;
      margin-top: 0px;
    }

    .malomo-unavailable {
      display: none;
      border-width: 0px;
    }
  }

  @media screen and (max-width: 767px) {
    .malomo-shipment-tracker-event-timestamp {
      -webkit-align-self: flex-start;
      -ms-flex-item-align: start;
      align-self: flex-start;
      font-size: 16px;
    }

    .malomo-shipment-tracker-delivery-date {
      font-size: 46px;
    }

    .tracking-container {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 0px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
    }

    .malomo-shipment-tracker-event-message {
      font-size: 16px;
      line-height: 135%;
    }

    .malomo-shipment-tracker-delivery-state {
      background-color: transparent;
      font-size: 12px;
    }

    .malomo-shipment-tracker-countdown {
      text-align: left;
    }

    .malomo-shipment-tracker-history-control {
      border: 1px solid #e3ebf6;
    }

    .malomo-shipment-tracker-footer {
      width: 100vw;
      margin-right: -24px;
      margin-left: -24px;
      padding-right: 24px;
      padding-left: 24px;
      border-top-style: none;
    }

    .malomo-shipment-tracker-body {
      position: static;
      z-index: 99;
      width: 100vw;
      max-width: none;
      margin-left: 0px;
      padding-top: 16px;
      padding-right: 16px;
      padding-left: 16px;
      border-width: 0px;
    }

    .malomo-unavailable-link {
      line-height: 200%;
    }

    .malomo-shipment-tracker-status-message {
      font-size: 46px;
    }

    .malomo-container-new {
      margin-bottom: 12px;
    }

    .malomo-order-lookup {
      position: static;
      z-index: 99;
      width: 100vw;
      max-width: none;
      margin-left: 0px;
      padding-top: 16px;
      padding-right: 16px;
      padding-left: 16px;
      border-width: 0px;
    }
  }

  @media screen and (max-width: 479px) {
    .malomo-shipment-tracker-event-timestamp {
      -webkit-align-self: auto;
      -ms-flex-item-align: auto;
      -ms-grid-row-align: auto;
      align-self: auto;
    }

    .malomo-shipment-tracker-event-timestamp-date {
      text-decoration: none;
      text-transform: none;
    }

    .malomo-shipment-tracker-delivery-date {
      -webkit-align-content: stretch;
      -ms-flex-line-pack: stretch;
      align-content: stretch;
    }

    .tracking-container {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .malomo-shipment-tracker-delivery-state {
      margin-bottom: 12px;
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
    }

    .malomo-shipment-tracker-history-control {
      margin-bottom: 12px;
      -webkit-align-self: stretch;
      -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch;
      -webkit-box-ordinal-group: 0;
      -webkit-order: -1;
      -ms-flex-order: -1;
      order: -1;
    }

    .malomo-shipment-tracker-footer {
      width: 100vw;
      margin-right: -16px;
      margin-left: -16px;
      padding-right: 16px;
      padding-left: 16px;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }

    .malomo-shipment-tracker-body {
      z-index: 99;
    }

    .malomo-shipment-tracker-status-message {
      -webkit-align-content: stretch;
      -ms-flex-line-pack: stretch;
      align-content: stretch;
    }

    .malomo-shipment-tracker-events-backdrop {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }

    .malomo-shipment-tracker-events-modal {
      position: static;
      width: 100%;
      height: 100%;
      border-radius: 0px;
    }

    .malomo-shipment-tracker-events {
      height: 100%;
      margin-top: 0px;
    }

    .malomo-order-lookup {
      z-index: 99;
      display: block;
      width: 100vw;
      margin-top: 500px;
      -webkit-align-self: center;
      -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
    }

    .malomo-unavailable {
      width: 100%;
      margin-top: 25px;
    }

    .malomo-unavailable-header {
      font-size: 48px;
    }
  }
`;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.passwordRecoveryCopy = void 0;
exports.passwordRecoveryCopy = {
    header: 'Reset Your Password',
    input1: 'Email Address',
    cta: 'Send Reset Link',
    prompt: 'Enter your email and we will send a link to change your password',
    heroHeader: 'Customer retention is the new growth strategy.',
    heroSubHeader: 'Plant the seeds for retention with a world-class post purchase experience.',
    confirmation: 'Success! Check your email for a link to reset your password.'
};

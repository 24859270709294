"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const styled_components_1 = require("styled-components");
//
// properties
//
const config_1 = require("assets/styles/config");
const GlobalStyle = (0, styled_components_1.createGlobalStyle) `
  #gjs .gjs-one-bg {
    background-color: ${config_1.colors.thundercloud};
  }

  #gjs .gjs-two-color {
    color: ${config_1.colors.grayBorder};
  }

  #gjs .gjs-three-bg {
    background-color: ${config_1.colors.thundercloud};
    color: ${config_1.colors.grayBorder};
  }

  #gjs .gjs-four-color,
  #gjs .gjs-four-color:hover {
    color: ${config_1.colors.grayBorder};
  }

  #gjs .gjs-clm-tags {
    display: none;
  }

  .gjs-mdl-add-font .gjs-btn-group {
    display: flex;
    justify-content: space-between;
    margin-top: 48px;
  }

  .gjs-form-add-font {
    box-sizing: border-box;
    padding: 14px;
    background-color: #fff;
  }

  .gjs-form-add-font::before,
  .gjs-form-add-font::after {
    box-sizing: inherit;
  }

  .gjs-form-add-font fieldset {
    margin: 0;
    padding: 0;
    border: none;
  }

  .gjs-form-add-font .gjs-form-group:not(:last-of-type) {
    margin-bottom: 14px;
  }

  .gjs-form-add-font .gjs-form-label {
    display: block;
    margin-bottom: 7px;
    color: #383838;
    font-size: 14px;
    font-weight: bold;
  }

  .gjs-form-add-font .gjs-form-control {
    box-sizing: border-box;
    width: 100%;
    padding: 12px;
    border: 1px solid #e3e3e3;
    background-color: #fff;

    &::before,
    &::after {
      box-sizing: inherit;
    }
  }

  .gjs-form-add-font .gjs-form-radio-group {
    display: inline-block;

    &:not(:last-of-type) {
      margin-right: 14px;
    }
  }

  .gjs-form-add-font .gjs-form-radio-control {
    display: none;
  }

  .gjs-form-add-font .gjs-form-radio-label {
    display: flex;
    position: relative;
    align-items: center;
    color: #383838;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;

    &::before {
      content: '';
      width: 14px;
      height: 14px;
      margin-right: 7px;
      border: 1px solid #e3e3e3;
      border-radius: 50%;
      background-color: #fff;
    }
  }

  .gjs-form-add-font .gjs-form-radio-control {
    display: none;
    
    &:checked + .gjs-form-radio-label::after  {
      content: '';
      position: absolute;
      left: 3px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #383838;
    }
  }
`;
exports.default = GlobalStyle;

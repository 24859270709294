"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRootConfig = void 0;
const consts_1 = require("./consts");
function getUpdatedHtml(html) {
    return html.replace(consts_1.shipmentTrackingSelector, consts_1.shipmentTrackingAttributes);
}
function getRootConfig() {
    return {
        'template.txt': (editor) => {
            const html = getUpdatedHtml(editor.getHtml());
            return `${html}<style>${editor.getCss()}</style>`;
        }
    };
}
exports.getRootConfig = getRootConfig;

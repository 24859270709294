"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.developerCopy = exports.accountCopy = void 0;
exports.accountCopy = {
    tab1: 'Overview',
    tab2: 'Billing',
    developerTab: 'Developer',
    title: 'Account'
};
exports.developerCopy = {
    noApiKeysYet: 'No API keys yet.',
    noApiKeysYetExplainer: 'Once an API Key is generated, you can find it here.'
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultFonts = void 0;
exports.defaultFonts = [
    {
        node: {
            name: 'Arial',
            family: 'Arial, Helvetica',
            url: '',
            weight: []
        }
    },
    {
        node: {
            name: 'Arial Black',
            family: 'Arial Black, Gadget',
            url: '',
            weight: []
        }
    },
    {
        node: {
            name: 'Brush Script MT',
            family: 'Brush Script MT',
            url: '',
            weight: []
        }
    },
    {
        node: {
            name: 'Comic Sans MS',
            family: 'Comic Sans MS, cursive',
            url: '',
            weight: []
        }
    },
    {
        node: {
            name: 'Courier New',
            family: 'Courier New, Courier, monospace',
            url: '',
            weight: []
        }
    },
    {
        node: {
            name: 'Georgia',
            family: 'Georgia, serif',
            url: '',
            weight: []
        }
    },
    {
        node: {
            name: 'Helvetica',
            family: 'Helvetica',
            url: '',
            weight: []
        }
    },
    {
        node: {
            name: 'Impact',
            family: 'Impact, Charcoal',
            url: '',
            weight: []
        }
    },
    {
        node: {
            name: 'Lucida Sans Unicode',
            family: 'Lucida Sans Unicode, Lucida Grande',
            url: '',
            weight: []
        }
    },
    {
        node: {
            name: 'Tahoma',
            family: 'Tahoma, Geneva',
            url: '',
            weight: []
        }
    },
    {
        node: {
            name: 'Times New Roman',
            family: 'Times New Roman, Times, serif',
            url: '',
            weight: []
        }
    },
    {
        node: {
            name: 'Trebuchet MS',
            family: 'Trebuchet MS, Helvetica',
            url: '',
            weight: []
        }
    },
    {
        node: {
            name: 'Verdana',
            family: 'Verdana, Geneva',
            url: '',
            weight: []
        }
    }
];

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.passwordResetCopy = void 0;
exports.passwordResetCopy = {
    header: 'Reset Your Password',
    input1: 'New Password',
    input2: 'Confirm New Password',
    cta: 'Reset',
    confirmation: 'Success!',
    prompt: 'Enter your new password',
    heroHeader: 'Customer retention is the new growth strategy.',
    heroSubHeader: 'Plant the seeds for retention with a world-class post purchase experience.'
};

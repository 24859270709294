"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const client_1 = require("@apollo/client");
const react_bootstrap_1 = require("react-bootstrap");
const polaris_1 = require("@shopify/polaris");
const StyledInputContainer_1 = require("components/Dashboard/Settings/StyledInputContainer");
const StyledInvalidFeedback_1 = require("components/Dashboard/StyledInvalidFeedback");
const queryDefinitions_1 = require("queryDefinitions");
const graphql_1 = require("./graphql");
const styles_1 = require("./styles");
const DEFAULT_BUTTON_STATUS = 'Save Settings';
const Communications = () => {
    const [weeklyPerformanceEmail, setWeeklyPerformanceEmail] = (0, react_1.useState)(true);
    const [buttonStatus, setButtonStatus] = (0, react_1.useState)(DEFAULT_BUTTON_STATUS);
    const { data: userData } = (0, client_1.useQuery)(queryDefinitions_1.GET_USER);
    const [updateCommunications, { data, loading, error }] = (0, client_1.useMutation)(graphql_1.UPDATE_USER_COMMUNICATIONS, {
        refetchQueries: [{ query: queryDefinitions_1.GET_USER }, 'currentUser']
    });
    function handleUpdateUsercommunications() {
        setButtonStatus('Loading...');
        updateCommunications({
            variables: { input: { weeklyPerformanceEmail: weeklyPerformanceEmail } }
        });
    }
    (0, react_1.useEffect)(() => {
        var _a;
        if (userData) {
            setWeeklyPerformanceEmail((_a = userData.currentUser) === null || _a === void 0 ? void 0 : _a.weeklyPerformanceEmail);
        }
    }, [userData]);
    (0, react_1.useEffect)(() => {
        if (data) {
            setButtonStatus('Success!');
            setTimeout(() => {
                setButtonStatus(DEFAULT_BUTTON_STATUS);
            }, 2000);
        }
        if (error) {
            setButtonStatus(DEFAULT_BUTTON_STATUS);
        }
    }, [data, error]);
    // eslint-disable-next-line no-shadow
    function displayApiError(error) {
        if (error.message === 'Failed to fetch') {
            return 'The server could not be reached, please try again later';
        }
        if (error.message.includes('blank')) {
            return 'All input is required, please try again';
        }
        if (error.message.includes('not_authenticated')) {
            return 'Please enter a valid email and password';
        }
        if (error.message) {
            return error.message;
        }
        return '';
    }
    return (react_1.default.createElement(react_bootstrap_1.Fade, { appear: true, in: true },
        react_1.default.createElement("div", null,
            react_1.default.createElement(StyledInputContainer_1.StyledInputContainer, null,
                react_1.default.createElement(styles_1.CheckBoxContainer, null,
                    react_1.default.createElement(polaris_1.Checkbox, { checked: weeklyPerformanceEmail, label: 'Send me weekly performance emails', onChange: () => setWeeklyPerformanceEmail(!weeklyPerformanceEmail) })),
                react_1.default.createElement(polaris_1.Button, { disabled: loading, onClick: handleUpdateUsercommunications, primary: true }, buttonStatus),
                error && (react_1.default.createElement(StyledInvalidFeedback_1.StyledInvalidFeedback, { className: 'api-error invalid-feedback' }, displayApiError(error)))))));
};
exports.default = Communications;

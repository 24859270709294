"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const utils_1 = require("./utils");
exports.default = (editor, config) => {
    const pfx = editor.getConfig().stylePrefix;
    const modal = editor.Modal;
    const container = document.createElement('div');
    const defaultProvider = 'google';
    const form = document.createElement('form');
    form.innerHTML = (0, utils_1.renderFieldset)(pfx);
    form.id = config.id;
    form.className = `${pfx}form-${config.id}`;
    const fieldset = form.querySelector('fieldset');
    fieldset === null || fieldset === void 0 ? void 0 : fieldset.insertAdjacentHTML('beforeend', (0, utils_1.renderFormGroups)(defaultProvider, pfx));
    form
        .querySelectorAll('input[name="provider"]')
        .forEach((elem) => {
        elem.addEventListener('change', (e) => {
            if (fieldset === null || fieldset === void 0 ? void 0 : fieldset.lastElementChild) {
                fieldset.removeChild(fieldset.lastElementChild);
                fieldset.insertAdjacentHTML('beforeend', 
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'eventTarget'.
                (0, utils_1.renderFormGroups)(e.target.value, pfx));
            }
        });
    });
    form.addEventListener('submit', (e) => {
        e.preventDefault();
        // @ts-expect-error Argument of type 'EventTarget | null' is not assignable to parameter of type 'HTMLFormElement | undefined'.
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const _a = Object.fromEntries(new FormData(e.target)), { _provider } = _a, data = __rest(_a, ["_provider"]);
        // config.onSubmit(JSON.stringify(data));
        // @ts-expect-error FIXME: Data is not assignable to type NewFont.
        config.onSubmit(data);
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'reset' does not exist on type 'eventTarget'.
        e.target.reset();
        modal.close();
    });
    const closeButton = document.createElement('button');
    closeButton.type = 'button';
    closeButton.innerHTML = 'Cancel';
    closeButton.className = `${pfx}btn ${pfx}btn-secondary ${pfx}btn-close-modal`;
    closeButton.addEventListener('click', function () {
        modal.close();
    }, false);
    const saveButton = document.createElement('button');
    saveButton.type = 'submit';
    saveButton.innerHTML = config.title;
    saveButton.className = `${pfx}btn ${pfx}btn-primary ${pfx}btn-${config.id}`;
    saveButton.setAttribute('form', config.id);
    const buttonGroup = document.createElement('div');
    buttonGroup.className = `${pfx}btn-group`;
    buttonGroup.appendChild(closeButton);
    buttonGroup.appendChild(saveButton);
    return {
        run() {
            container.appendChild(form);
            container.appendChild(buttonGroup);
            modal === null || modal === void 0 ? void 0 : modal.open({
                title: config.title,
                content: container,
                attributes: { class: 'gjs-mdl-add-font' }
            }).onceClose(() => {
                if (fieldset === null || fieldset === void 0 ? void 0 : fieldset.lastElementChild) {
                    fieldset.removeChild(fieldset.lastElementChild);
                    fieldset.insertAdjacentHTML('beforeend', (0, utils_1.renderFormGroups)(defaultProvider, pfx));
                }
                form.reset();
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'stopCommand' does not exist on type 'this'.
                this.stopCommand();
            });
        },
        stop() {
            modal.close();
        }
    };
};

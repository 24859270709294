"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackingPagesContainer = void 0;
const react_1 = __importStar(require("react"));
const client_1 = require("@apollo/client");
const react_error_boundary_1 = require("react-error-boundary");
const polaris_1 = require("@shopify/polaris");
const AccountContext_1 = require("contexts/AccountContext");
const StyledMainContainer_1 = require("components/Dashboard/StyledMainContainer");
const CreateTrackingPageModal_1 = require("components/Dashboard/TrackingPages/CreateTrackingPageModal");
const DeleteTrackingPageModal_1 = require("components/Dashboard/TrackingPages/DeleteTrackingPageModal");
const ListTrackingPages_1 = require("components/Dashboard/TrackingPages/ListTrackingPages");
const TrackingPageAlert_1 = require("components/Dashboard/TrackingPages/TrackingPageAlert");
const EmptyState_1 = require("components/Dashboard/TrackingPages/EmptyState");
const Loading_1 = require("components/Dashboard/TrackingPages/Loading");
const graphql_1 = require("components/Dashboard/TrackingPages/graphql");
const styles_1 = require("./styles");
const alertOptions = {
    CREATE_TRACKING_PAGE: 'create-tracking-page',
    DELETE_TRACKING_PAGE: 'delete-tracking-page',
    SET_DEFAULT_TRACKING_PAGE_URL: 'set-default-tracking-page-url'
};
const TrackingPagesContainer = () => {
    const handleError = (0, react_error_boundary_1.useErrorHandler)();
    const { account } = (0, AccountContext_1.useAccount)();
    const [createTrackingPageModalIsActive, setCreateTrackingPageModalIsActive] = (0, react_1.useState)(false);
    const [deleteTrackingPageModalIsActive, setDeleteTrackingPageModalIsActive] = (0, react_1.useState)(false);
    const [selectedTrackingPage, setSelectedTrackingPage] = (0, react_1.useState)(null);
    const [selectedAlert, setSelectedAlert] = (0, react_1.useState)('');
    const [selectedTitle, setSelectedTitle] = (0, react_1.useState)('');
    const divRef = (0, react_1.useRef)(null);
    const [getTrackingPages, { loading, error, data }] = (0, client_1.useLazyQuery)(graphql_1.GET_TRACKING_PAGES);
    (0, react_1.useEffect)(() => {
        if (account === null || account === void 0 ? void 0 : account.id) {
            getTrackingPages({ variables: { accountId: account.id } });
        }
    }, [account === null || account === void 0 ? void 0 : account.id]);
    const handleOpenCreateTrackingPageModal = (0, react_1.useCallback)(() => {
        setCreateTrackingPageModalIsActive(true);
    }, []);
    const handleCloseCreateTrackingPageModal = (0, react_1.useCallback)(() => {
        setCreateTrackingPageModalIsActive(false);
        requestAnimationFrame(() => { var _a, _b; return (_b = (_a = divRef.current) === null || _a === void 0 ? void 0 : _a.querySelector('button')) === null || _b === void 0 ? void 0 : _b.focus(); });
    }, []);
    const handleOpenDeleteTrackingPageModal = (0, react_1.useCallback)(() => {
        setDeleteTrackingPageModalIsActive(true);
    }, []);
    const handleCloseDeleteTrackingPageModal = (0, react_1.useCallback)(() => {
        setSelectedTrackingPage(null);
        setDeleteTrackingPageModalIsActive(false);
    }, []);
    const handleScrollToTop = (0, react_1.useCallback)(() => {
        const container = document.querySelector('#dashboard-container');
        container === null || container === void 0 ? void 0 : container.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    const trackingPages = (0, react_1.useMemo)(() => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.externalTrackingPage) !== null && _a !== void 0 ? _a : []; }, [data]);
    if (error) {
        handleError(error);
    }
    if (loading) {
        return react_1.default.createElement(Loading_1.Loading, null);
    }
    const isEmpty = trackingPages.length === 0;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("div", { className: 'fade show' },
            react_1.default.createElement(StyledMainContainer_1.StyledMainContainer, { className: 'tracking-pages' },
                react_1.default.createElement(styles_1.HeaderContainer, Object.assign({}, (!isEmpty ? { ref: divRef } : {})),
                    react_1.default.createElement(styles_1.Header, { "aria-level": 1, className: 'header', role: 'heading' }, "Tracking Page"),
                    !isEmpty && (react_1.default.createElement(polaris_1.Button, { onClick: handleOpenCreateTrackingPageModal, primary: true }, "Create a Tracking Page"))),
                react_1.default.createElement(TrackingPageAlert_1.TrackingPageAlert, { active: selectedAlert === alertOptions.CREATE_TRACKING_PAGE, onDismiss: () => {
                        setSelectedAlert('');
                        setSelectedTitle('');
                    }, title: selectedTitle },
                    react_1.default.createElement("p", null,
                        "Success! Your tracking page is published, you can view your page in the link below. You can also customize your page in the Shopify Theme Editor by clicking on the ",
                        react_1.default.createElement(styles_1.Span, null, "Customize My Page"),
                        ' ',
                        "button.")),
                react_1.default.createElement(TrackingPageAlert_1.TrackingPageAlert, { active: selectedAlert === alertOptions.SET_DEFAULT_TRACKING_PAGE_URL, onDismiss: () => {
                        setSelectedAlert('');
                        setSelectedTitle('');
                    }, title: selectedTitle },
                    react_1.default.createElement("p", null, "You can edit your default tracking page URL at any time in your account settings.")),
                react_1.default.createElement(TrackingPageAlert_1.TrackingPageAlert, { active: selectedAlert === alertOptions.DELETE_TRACKING_PAGE, onDismiss: () => {
                        setSelectedAlert('');
                        setSelectedTitle('');
                    }, title: selectedTitle }),
                isEmpty && (react_1.default.createElement(EmptyState_1.EmptyState, { divRef: divRef, onAction: handleOpenCreateTrackingPageModal })),
                !isEmpty && (react_1.default.createElement(ListTrackingPages_1.ListTrackingPages, { onChangeSelectedTrackingPage: setSelectedTrackingPage, onChangeSuccessAlert: () => setSelectedAlert(alertOptions.SET_DEFAULT_TRACKING_PAGE_URL), onChangeSuccessTitle: setSelectedTitle, onOpenDeleteModal: handleOpenDeleteTrackingPageModal, onScrollToTop: handleScrollToTop, selectedTrackingPage: selectedTrackingPage, trackingPages: trackingPages })))),
        react_1.default.createElement(CreateTrackingPageModal_1.CreateTrackingPageModal, { active: createTrackingPageModalIsActive, onChangeSuccessAlert: () => setSelectedAlert(alertOptions.CREATE_TRACKING_PAGE), onChangeSuccessTitle: setSelectedTitle, onClose: handleCloseCreateTrackingPageModal }),
        react_1.default.createElement(DeleteTrackingPageModal_1.DeleteTrackingPageModal, { active: deleteTrackingPageModalIsActive, onChangeSuccessAlert: () => setSelectedAlert(alertOptions.DELETE_TRACKING_PAGE), onChangeSuccessTitle: setSelectedTitle, onClose: handleCloseDeleteTrackingPageModal, onScrollToTop: handleScrollToTop, selectedTrackingPage: selectedTrackingPage })));
};
exports.TrackingPagesContainer = TrackingPagesContainer;

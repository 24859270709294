"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ADD_FONT = exports.GET_PAGE = exports.GET_FONTS = exports.GET_ASSET_URL = exports.GET_ASSETS = exports.PUBLISH_PAGE = void 0;
const client_1 = require("@apollo/client");
exports.PUBLISH_PAGE = (0, client_1.gql) `
  mutation PublishTrackingPage($input: PublishTrackingPageInput!) {
    publishTrackingPage(input: $input) {
      trackingPage {
        __typename
      }
    }
  }
`;
exports.GET_ASSETS = (0, client_1.gql) `
  query GetTrackingPageAssets($accountId: ID!, $trackingPageId: ID!) {
    trackingPageAssets(accountId: $accountId, trackingPageId: $trackingPageId)
  }
`;
exports.GET_ASSET_URL = (0, client_1.gql) `
  mutation CreateTrackingPageAssetSignedUrl(
    $input: CreateTrackingPageAssetSignedUrlInput!
  ) {
    createTrackingPageAssetSignedUrl(input: $input) {
      s3Path
      url
      version
    }
  }
`;
exports.GET_FONTS = (0, client_1.gql) `
  query getTrackingPageFontFaces(
    $accountId: ID!
    $trackingPageId: ID!
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    trackingPageFontFaces(
      accountId: $accountId
      trackingPageId: $trackingPageId
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      edges {
        node {
          family
          name
          url
          weight
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;
exports.GET_PAGE = (0, client_1.gql) `
  query trackingPage($accountId: ID!, $id: ID!) {
    trackingPage(accountId: $accountId, id: $id) {
      html
      name
      __typename
    }
  }
`;
exports.ADD_FONT = (0, client_1.gql) `
  mutation CreateTrackingPageFontFace(
    $input: CreateTrackingPageFontFaceInput!
  ) {
    createTrackingPageFontFace(input: $input) {
      trackingPageFontFace {
        id
      }
    }
  }
`;

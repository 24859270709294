"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
require("../../css/modules/general/ModalContainer.css");
const react_bootstrap_1 = require("react-bootstrap");
class ModalContainer extends react_1.Component {
    constructor() {
        super(...arguments);
        this.handleClick = (event) => {
            if (event.target.id === 'modal-root') {
                this.props.onToggleModal();
            }
        };
    }
    componentDidUpdate() {
        if (this.props.display) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.removeAttribute('style');
        }
    }
    render() {
        return (react_1.default.createElement("div", { "aria-hidden": 'true', className: 'modal fade show', id: 'modal-root', onClick: this.handleClick, role: 'dialog', style: {
                display: `${this.props.display ? 'block' : 'none'}`
            }, tabIndex: -1 },
            react_1.default.createElement("div", { className: 'modal-dialog modal-dialog-centered modal-container', role: 'document' },
                react_1.default.createElement(react_bootstrap_1.Fade, { in: this.props.display },
                    react_1.default.createElement("div", { className: 'modal-content' }, this.props.children)))));
    }
}
exports.default = ModalContainer;

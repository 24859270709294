"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTextTag = exports.toggleSectors = void 0;
const toggleSectors = (selector, className) => {
    document.querySelectorAll(selector).forEach((elem) => {
        elem.classList.toggle(className);
    });
};
exports.toggleSectors = toggleSectors;
const isTextTag = (tagName) => {
    return [
        'p',
        'span',
        'h1',
        'h2',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'strong',
        'em',
        'blockquote',
        'q',
        'code',
        'pre',
        'mark',
        'ins',
        'del',
        'sup',
        'sub',
        'small',
        'i',
        'b'
    ].includes(tagName);
};
exports.isTextTag = isTextTag;

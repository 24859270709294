"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const client_1 = require("@apollo/client");
const react_error_boundary_1 = require("react-error-boundary");
const react_router_dom_1 = require("react-router-dom");
const AccountContext_1 = require("contexts/AccountContext");
const Loading_1 = require("./Loading");
const TrackingPagesCheck_1 = require("./TrackingPagesCheck");
const TrackingPagesContainer_1 = require("./TrackingPagesContainer");
const graphql_1 = require("components/Dashboard/Apps/graphql");
const graphql_2 = require("./graphql");
const TrackingPages = () => {
    var _a;
    const handleError = (0, react_error_boundary_1.useErrorHandler)();
    const { account } = (0, AccountContext_1.useAccount)();
    const location = (0, react_router_dom_1.useLocation)();
    const [shopifyInstallUrl, setShopifyInstallUrl] = (0, react_1.useState)(null);
    const [checkShopifyScopes, { loading: checkShopifyScopesLoading, data: checkShopifyScopesData, error: checkShopifyScopesError }] = (0, client_1.useLazyQuery)(graphql_2.CHECK_SHOPIFY_SCOPES, { fetchPolicy: 'no-cache' });
    const { loading: appListingsLoading, error: appListingsError } = (0, client_1.useQuery)(graphql_1.GET_APP_LISTINGS, {
        fetchPolicy: 'no-cache',
        onCompleted({ appListings }) {
            const shopifyApp = appListings.find((app) => app.name === 'Shopify');
            if (shopifyApp === null || shopifyApp === void 0 ? void 0 : shopifyApp.installed) {
                setShopifyInstallUrl(null);
                checkShopifyScopes({
                    variables: {
                        input: {
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            accountId: account.id,
                            callbackPath: location.pathname,
                            scopes: ['write_themes', 'write_content']
                        }
                    }
                });
            }
            else if ((shopifyApp === null || shopifyApp === void 0 ? void 0 : shopifyApp.installed) === false) {
                setShopifyInstallUrl(shopifyApp.installUrl);
            }
            else {
                throw new Error('Shopify was not found in app listings.');
            }
        },
        skip: !(account === null || account === void 0 ? void 0 : account.id),
        variables: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            accountId: account.id
        }
    });
    if (appListingsError || checkShopifyScopesError) {
        handleError(appListingsError || checkShopifyScopesError);
    }
    if (appListingsLoading || checkShopifyScopesLoading) {
        return react_1.default.createElement(Loading_1.Loading, null);
    }
    if (shopifyInstallUrl) {
        return (react_1.default.createElement(TrackingPagesCheck_1.TrackingPagesCheck, { action: {
                content: 'Install Shopify',
                external: true,
                url: shopifyInstallUrl
            }, message: 'You need to install Shopify before you can create a tracking page.', title: "Malomo Isn't Connected To Shopify" }));
    }
    if ((checkShopifyScopesData === null || checkShopifyScopesData === void 0 ? void 0 : checkShopifyScopesData.shopifyCheckScopes.hasRequiredScopes) === false) {
        return (react_1.default.createElement(TrackingPagesCheck_1.TrackingPagesCheck, { action: {
                content: 'Update Shopify Permissions',
                external: false,
                url: (_a = checkShopifyScopesData === null || checkShopifyScopesData === void 0 ? void 0 : checkShopifyScopesData.shopifyCheckScopes.reauthorizeUrl) !== null && _a !== void 0 ? _a : ''
            }, message: 'You need to update Shopify permissions in order to create a tracking page.', title: 'Shopify Permissions Out of Date' }));
    }
    return react_1.default.createElement(TrackingPagesContainer_1.TrackingPagesContainer, null);
};
exports.default = TrackingPages;

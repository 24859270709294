"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.renderFieldset = exports.renderFormGroups = void 0;
function getPlaceholders(provider) {
    return {
        google: {
            fontName: 'Roboto',
            fontFamily: 'Roboto, sans-serif',
            source: 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,900&display=swap'
        },
        adobe: {
            fontName: 'Brandon Grotesque',
            fontFamily: 'brandon-grotesque, sans-serif',
            source: 'https://use.typekit.net/mqs3jrg.css'
        },
        custom: {
            fontName: '',
            fontFamily: '',
            source: ''
        }
    }[provider];
}
function renderFormGroups(provider, stylePrefix = '') {
    const { fontName, fontFamily, source } = getPlaceholders(provider);
    return `
    <div>
      <div class="${stylePrefix}form-group">
        <label for="fontName" class="${stylePrefix}form-label">Font name</label>
        <input name="fontName" type="text" class="${stylePrefix}form-control" required placeholder="${fontName}">
      </div>
      <div class="${stylePrefix}form-group">
        <label for="fontFamily" class="${stylePrefix}form-label">Font family</label>
        <input name="fontFamily" type="text" class="${stylePrefix}form-control" required placeholder="${fontFamily}">
      </div>
      <div class="${stylePrefix}form-group">
        <label for="source" class="${stylePrefix}form-label">
        ${provider === 'custom' ? 'Source URL' : 'CSS URL'}
        </label>
        <input name="source" type="text" class="${stylePrefix}form-control" required placeholder="${source}">
      </div>
    </div>
  `;
}
exports.renderFormGroups = renderFormGroups;
function renderFieldset(stylePrefix = '') {
    return `
    <fieldset>
      <div class="${stylePrefix}form-group">
        <div class="${stylePrefix}form-radio-group">
          <input id="google" name="provider" type="radio" class="${stylePrefix}form-radio-control" value="google" checked>
          <label class="${stylePrefix}form-radio-label" for="google">
            Google Fonts
          </label>
        </div>
        <div class="${stylePrefix}form-radio-group">
          <input id="adobe" name="provider" type="radio" class="${stylePrefix}form-radio-control" value="adobe">
          <label class="${stylePrefix}form-radio-label" for="adobe">
            Adobe Fonts
          </label>
        </div>
        <div class="${stylePrefix}form-radio-group">
          <input id="custom" name="provider" type="radio" class="${stylePrefix}form-radio-control" value="custom">
          <label class="${stylePrefix}form-radio-label" for="custom">
            Custom Fonts
          </label>
        </div>
      </div>
    </fieldset>
  `;
}
exports.renderFieldset = renderFieldset;

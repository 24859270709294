"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GET_SHOPIFY_DOMAINS = exports.CHECK_SHOPIFY_SCOPES = exports.DELETE_TRACKING_PAGE = exports.CREATE_TRACKING_PAGE = exports.GET_TRACKING_PAGES = void 0;
const client_1 = require("@apollo/client");
const TRACKING_PAGE_FIELDS = (0, client_1.gql) `
  fragment TrackingPageFields on ExternalTrackingPage {
    baseUrl
    description
    id
    insertedAt
    isDefault
    pageSlug
    shopifyEditUrl
    title
    url
  }
`;
exports.GET_TRACKING_PAGES = (0, client_1.gql) `
  ${TRACKING_PAGE_FIELDS}
  query externalTrackingPage($accountId: ID!) {
    externalTrackingPage(accountId: $accountId) {
      ...TrackingPageFields
    }
  }
`;
exports.CREATE_TRACKING_PAGE = (0, client_1.gql) `
  ${TRACKING_PAGE_FIELDS}
  mutation createExternalTrackingPage(
    $input: CreateExternalTrackingPageInput!
  ) {
    createExternalTrackingPage(input: $input) {
      externalTrackingPage {
        ...TrackingPageFields
      }
    }
  }
`;
exports.DELETE_TRACKING_PAGE = (0, client_1.gql) `
  mutation deleteExternalTrackingPage(
    $input: DeleteExternalTrackingPageInput!
  ) {
    deleteExternalTrackingPage(input: $input) {
      success
    }
  }
`;
exports.CHECK_SHOPIFY_SCOPES = (0, client_1.gql) `
  query ShopifyCheckScopes($input: ShopifyCheckScopesInput!) {
    shopifyCheckScopes(input: $input) {
      hasRequiredScopes
      reauthorizeUrl
    }
  }
`;
exports.GET_SHOPIFY_DOMAINS = (0, client_1.gql) `
  query ShopifyGetDomains($input: ShopifyGetDomainsInput!) {
    shopifyGetDomains(input: $input) {
      domain
      myshopifyDomain
    }
  }
`;

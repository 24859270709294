"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.registerCopy = void 0;
exports.registerCopy = {
    header: 'Sign up for Malomo',
    input1: 'Account Name',
    input2: 'Your Domain',
    input3: 'Name',
    input4: 'Email Address',
    input5: 'Create a Password',
    cta: 'Sign Up',
    linkPreface: 'Already have an account?',
    linkPrompt: 'Log in.',
    linkHref: '/login',
    heroHeader: 'Customer retention is the new growth strategy.',
    heroSubHeader: 'Plant the seeds for retention with a world-class post purchase experience.'
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const consts_1 = require("./consts");
const utils_1 = require("./utils");
exports.default = (editor, config) => {
    const styleManager = editor.StyleManager;
    const { fonts, customFonts, customFontWeights } = config;
    const fontWeight = styleManager === null || styleManager === void 0 ? void 0 : styleManager.getProperty(consts_1.SECTOR_OPTIONS.TYPOGRAPHY, 'font-weight');
    const fontFamily = styleManager === null || styleManager === void 0 ? void 0 : styleManager.getProperty(consts_1.SECTOR_OPTIONS.TYPOGRAPHY, 'font-family');
    fontFamily.setOptions(fonts);
    editor.on(
    // @ts-expect-error FIXME: Not a 'GrapesJSEvent' .
    'component:styleUpdate:font-family', (component, propChanged) => {
        if (customFonts.includes(propChanged)) {
            const filteredOptions = consts_1.FONT_WEIGHT_OPTIONS.filter((el) => customFontWeights[propChanged].includes(el.value));
            fontWeight.setOptions(filteredOptions);
        }
        else {
            fontWeight.setOptions(consts_1.FONT_WEIGHT_OPTIONS);
        }
    });
    editor.on('component:selected', (component) => {
        if (component.tagName === 'img') {
            (0, utils_1.toggleSectors)(`.${consts_1.BASE_CLASS}:not(.${consts_1.BASE_CLASS}__${consts_1.SECTOR_OPTIONS.DECORATE})`, consts_1.HIDE_CLASS);
            styleManager === null || styleManager === void 0 ? void 0 : styleManager.getSector(consts_1.SECTOR_OPTIONS.DECORATE).set({ open: true });
        }
        if ((0, utils_1.isTextTag)(component.tagName)) {
            (0, utils_1.toggleSectors)(`.${consts_1.BASE_CLASS}:not(.${consts_1.BASE_CLASS}__${consts_1.SECTOR_OPTIONS.TYPOGRAPHY})`, consts_1.HIDE_CLASS);
            styleManager === null || styleManager === void 0 ? void 0 : styleManager.getSector(consts_1.SECTOR_OPTIONS.TYPOGRAPHY).set({ open: true });
        }
    });
    editor.on('component:deselected', (component) => {
        fontWeight.setOptions(consts_1.FONT_WEIGHT_OPTIONS);
        if (component.tagName === 'img') {
            (0, utils_1.toggleSectors)(`.${consts_1.BASE_CLASS}:not(.${consts_1.BASE_CLASS}__${consts_1.SECTOR_OPTIONS.DECORATE})`, consts_1.HIDE_CLASS);
            styleManager === null || styleManager === void 0 ? void 0 : styleManager.getSector(consts_1.SECTOR_OPTIONS.DECORATE).set({ open: false });
        }
        if ((0, utils_1.isTextTag)(component.tagName)) {
            (0, utils_1.toggleSectors)(`.${consts_1.BASE_CLASS}:not(.${consts_1.BASE_CLASS}__${consts_1.SECTOR_OPTIONS.TYPOGRAPHY})`, consts_1.HIDE_CLASS);
            styleManager === null || styleManager === void 0 ? void 0 : styleManager.getSector(consts_1.SECTOR_OPTIONS.TYPOGRAPHY).set({ open: false });
        }
    });
};
